$field_height: 36px;

.app__custom-select {
  width: 212px;
  height: $field_height;
  border: 1px solid $form_element_color;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 25px;
  /*overflow: hidden;*/

  &.subject {
    width: 889px;
    margin-bottom: 25px;
  }
}

.recipient-select.app__custom-select {
  width: 212px;
  height: 20px;
  border: 1px solid $table_border;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 0px;
  outline: none;

  &.subject {
    width: 260px;
    margin-bottom: 0px;
  }

  .app__custom-select__main {
    height: 18px;
    background-color: #fff;
    text-align: center;
    overflow: hidden;

    p {
      color: #000;
    }
  }

  ::after {
    background: $table_border;
    padding: 3px;
    height: 18px;
  }

  .app__custom-select__list {
    top: 18px;
  }

  .app__custom-select__list-items {
    border: 1px solid $table_border;
    border-top: none;
  }

  .app__custom-select__list-items li {
    padding: 5px;
    color: #000;
    border: none;
    text-align: center;

    &:nth-child(odd) {
      background: #dbdbdb;
    }
  }

}


.app__custom-select__main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: $field_height;
  text-align: left;
  box-sizing: border-box;
  padding: 0px 40px 0 5px;
  font-size: 11px;
  cursor: pointer;
}

.app__custom-select__main p {
  margin: 0;
  padding: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.app__custom-select__main::after {
  box-sizing: border-box;
  color: $text_white;
  /*font-family: "Font Awesome 5 Free"; 
	  font-weight: 900;
	  font-size: 20px;*/
  content: url(/img/down-arrow.svg);
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px 9px;
  height: calc(100% - 2px);
  background: $form_element_color;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}

.skin2 .app__custom-select__main::after {
  background: $form_element_color_2;
}

.skin2 .app__custom-select {
  border: 1px solid $form_element_color_2;
}

.skin3 .app__custom-select__main::after {
  background: $form_element_color_3;
}

.skin3 .app__custom-select {
  border: 1px solid $form_element_color_3;
}

.skin4 .app__custom-select__main::after {
  background: $teriary_form_color_4;
}
.skin4 {
  border: 1px solid $form_element_color_4;
}

.skin5 .app__custom-select__main::after {
  background: $teriary_form_color_5;
}
.skin5 {
  border: 1px solid $form_element_color_5;
}

.app__custom-select__list {
  width: calc(100% + 2px);
  position: absolute;
  text-align: left;
  top: 34px;
  left: -1px;
  box-sizing: border-box;
  overflow: hidden;
  height: 350px;
}

.app__custom-select__list-items {
  list-style: none;
  position: absolute;
  z-index: 100;
  background-color: $text_white;
  border: 1px solid $form_element_color;
  width: 100%;
  font-size: 11px;
  max-height: 350px;
  overflow-y: auto;
  box-sizing: border-box;
  left: 0;
  top: -350px;
  transition: 0.4s;
}

.app__custom-select__list-items li {
  border-bottom: 1px solid $table_border;
  padding: 12px 6px;
  cursor: pointer;
  &:last-of-type {
    border-bottom: none;
  }
}
