.admin a {
  color: $admin_link;
  float: right;
  font-size: 12px;
}

.admin .app__container {
  border: 0;
  font-family: $admin_font;
  min-height: calc(100vh - 285px);
}

.admin .app__header {
  background-color: $admin_header_color;
  background-image: none;
  margin-bottom: 46px;
}

.admin .app__login-panel {
  box-sizing: border-box;
  width: 326px;
  height: 480px;
  border-radius: 8px;
  box-shadow: 0 0 16px 8px $admin_glow;
  margin: 0 auto;
  padding: 30px 30px 0;
  font-size: 14px;
  margin-bottom: 48px;
  position: relative;
}

.admin .app__login-panel-sso {
  box-sizing: border-box;
  width: 500px;
  height: 111px;
  border-radius: 8px;
  box-shadow: 0 0 16px 8px $admin_glow;
  margin: 0 auto;
  padding: 30px 30px 0;
  font-size: 14px;
  margin-bottom: 48px;
  position: relative;
}

.admin .app__login-panel-sso .admin__error-div--welcome {
  top: 50px;
  font-size: 16px;
}

.admin .app__login-panel p {
  text-align: center;
  color: $admin_text;
  font-size: 18px;
}

.admin .app__login-panel label > p {
  margin: 0 0 4px;
  padding: 0;
  color: $admin_form_label;
  text-align: left;
  font-size: 14px;
}

.admin .app__login-panel p.no-link {
  margin-top: 74px;
}

.admin .app__login-panel p.welcome {
  font-size: 16px;
  opacity: 0.6;
}

.admin .app__login-panel .admin__input-text--alt + p {
  color: $admin_text;
}

.admin .app__login-panel p.forgotPassword {
  float: right;
  color: $admin_warning;
  margin-top: -10px;
}

.admin .app__login-panel p.forgotPassword a {
  color: $admin_warning;
  text-decoration: none;
  font-weight: 400;
}

.admin .app__login-panel__title {
  color: $admin-heading;
  font-size: 30px;
  line-height: 28px;
  margin: 0;
  text-align: center;
  margin-bottom: 35px;
  font-weight: 700;
}

.admin .app__login-panel__title.app__login-panel__title--welcome {
  margin-bottom: 8px;
}

.admin .admin__form {
  margin-top: 5px;
}

.admin .admin__form.admin__form--forgot {
  margin-top: 55px;
}

.admin .admin__input-text {
  //this is the closed-box version from Reset Password
  display: block;
  height: 32px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $admin_form_element;
  margin-bottom: 16px;
  font-family: $admin_font;
  padding-left: 5px;
  font-size: 14px;
}

.admin .admin__input-text.admin__input-text--alt {
  //this is the open line version from Forgot Password
  border: 0;
  border-bottom: 1px solid $admin_form_element2;
  margin-bottom: 19px;
  padding-left: 1px;
  height: 25px;
  font-weight: bold;
}

.admin .admin__input-text.email-input::before {
  content: "mail";
  width: 18px;
  height: 15px;
}

.admin .admin__button {
  background-color: $admin_button_color;
  color: $text_white;
  border-radius: 24px;
  height: 45px;
  width: 192px;
  font-size: 16px;
  font-weight: 600;
  font-family: $admin_font;
  box-shadow: 0 10px 10px $admin_button_shadow;
  cursor: pointer;
  bottom: 114px;
  left: calc(50% - 96px);
  position: absolute;
  border: none;
}

.admin .admin__logo {
  height: 53px;
  width: 158px;
  background-image: url(/img/collegium-logo.png);
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  left: calc(50% - 79px);
  bottom: 32px;
}

.admin__error-div {
  width: 100%;
  font-size: 12px;
  color: $admin_warning;
  font-weight: bold;
  font-family: $admin_font;
  text-align: center;
  position: absolute;
  left: 0;
}

.admin__error-div--resetpassword {
  top: 290px;
}

.admin__error-div--welcome {
  top: 295px;
}
