.app__profile {
  button: {
    cursor: pointer;
  }
}

.app__profile__top-area {
  box-sizing: border-box;
  padding: 48px 51px 0;
  font-family: $main-font;
  position: relative;
}

.app__profile__bottom-area {
  box-sizing: border-box;
  padding: 0 23px 0;
  font-family: $main-font;
  position: relative;
  margin-top: 10px;
}

.app__profile__big-icon {
  width: 124px;
  height: 124px;
  background-image: url(/img/user-icon.png);
  background-size: cover;
  float: left;
  border-radius: 50%;
}

.app__profile__inputlist {
  float: left;
  margin-left: 22px;
}

.app__profile__input-text__label {
  display: block;
  margin: 0 12px 2px 0;
  clear: both;
}

.app__profile__input-text__label-text {
  float: left;
  background-color: $secondary_form_color;
  font-size: 10px;
  text-transform: uppercase;
  border-top: 1px solid $med_grey;
  border-bottom: 1px solid $med_grey;
  border-left: 1px solid $med_grey;
  box-sizing: border-box;
  height: 32px;
  width: 90px;
  color: $text_white;
  text-align: center;
  padding-top: 9px;
}

.app__profile__input-text {
  float: left;
  height: 32px;
  width: 400px;
  box-sizing: border-box;
  border: 1px solid $med_grey;
  padding-left: 29px;
  font-size: 12px;
}

.app__profile__button {
  height: 32px;
  width: 150px;
  box-sizing: border-box;
  background-color: $navigation_color;
  color: $text_white;
  bottom: 2px;
  border: none;
  font-size: 13px;
  font-weight: 700;
  border-radius: 6px;
  cursor: pointer;
  float: right;
  clear: right;
  margin-bottom: 20px;
  outline: none;
}

.app__profile__button.active {
  background-color: $form_element_color;
  outline: none;
}

.skin2 .app__profile__button.active {
  background-color: $form_element_color_2;
}

.skin3 .app__profile__button.active {
  background-color: $form_element_color_3;
}

.skin4 .app__profile__button.active {
  background-color: $form_element_color_4;
}

.skin5 .app__profile__button.active {
  background-color: $form_element_color_5;
}


.app__profile__update {
  height: 32px;
  width: 90px;
  box-sizing: border-box;
  background-color: transparent;
  color: $navigation_color;
  bottom: 2px;
  float: right;
  border: none;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
}

.app__profile__ok {
  height: 41px;
  width: 60px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: $navigation_color;
  color: $text_white;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid $table_border;
  opacity: 1;
  transition: opacity 0.2s;
  &:hover {
    background-color: $navigation_hover;
  }
}

.app__profile__tab {
  width: 137px;
  height: 32px;
  background-color: $form_element_color;
  color: $text_white;
  font-family: $main-font;
  font-size: 12px;
  border-radius: 12px 12px 0 0;
  box-sizing: border-box;
  text-align: center;
  padding-top: 9px;
}

.app__password__form {
  height: 116px;
  width: 100%;
  background-color: $form_element_color;
  text-align: center;
  padding-top: 38px;
  box-sizing: border-box;
  position: relative;
  transition: 0.5s;
}

.app__profile__password-input {
  border: 1px solid $table_border;
  background-color: $text_white;
  width: 238px;
  height: 41px;
  font-size: 16px;
  box-sizing: border-box;
  padding-left: 12px;
}

.app__profile__password-label {
  display: inline-block;
  margin-right: 10px;
  text-align: left;
  &:last-of-type {
    /*margin-right: 0;*/
  }
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s, visibility 0.2s;
}

.app__profile__password-label p {
  color: $text_white;
  font-weight: 700;
  font-size: 14px;
  margin: 6px 0 0;
}

.app__profile__table-heading {
  font-weight: 400;
  font-size: 12px;
  color: $form_element_color;
  margin: 20px 0 5px;
}

.app__profile__activity-table {
  width: calc(100% + 12px);
  margin-left: -6px;
  box-sizing: border-box;
  /*border-collapse: collapse;*/
  table-layout: fixed;
}

.app__profile__activity-table th {
  height: 30px;
  font-size: 14px;
}

.app__profile__activity-table tr {
  border-bottom: 1px;
}

.app__profile__activity-table th {
  background-color: $tab-color;
  color: $text_white;
  border: 1px solid $tab_border;
  margin: 0 3px;
  height: 100%;
  box-sizing: border-box;
  &:first-of-type {
    margin: 0 0 0 3px;
  }
  &:last-of-type {
    margin: 0 3px 0 0;
  }
  //width: 100%;
}

.app__profile__activity-table td {
  font-size: 12px;
  line-height: 12px;
  color: $alt_text_color;

  height: 64px;
}

.app__profile__error-div {
  width: 100%;
  font-size: 14px;
  color: $admin_warning;
  font-weight: bold;
  font-family: $main_font;
  text-align: center;
  position: absolute;
  top: 11px;
}

.app__profile__error-div--top {
  width: auto;
  margin: 10px 0 0 146px;
}

.app__profile__logout {
  font-size: 12px;
  color: $form_element_color;
  position: absolute;
  top: 10px;
  right: 60px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.app__profile__hide-show {
  position: absolute;
  width: 47px;
  height: 13px;
  background-image: url(/img/hide_sprite.png);
  top: 10px;
  right: 12px;
  cursor: pointer;
  z-index: 500;
  background-position: 0 -13px;
}

.app__password__form--hidden {
  height: 30px;
  .app__profile__password-label,
  .app__profile__ok,
  .app__profile__error-div {
    /*display: none;*/
    opacity: 0;
    visibility: hidden;
  }
  .app__profile__hide-show {
    background-position: 0 0;
  }
}

.skin2 {
  .app__profile__input-text__label-text {
    background-color: $secondary_form_color_2;
  }
  .app__profile__tab {
    background-color: $secondary_form_color_2;
  }
  .app__password__form {
    background-color: $secondary_form_color_2;
  }
  .app__profile__input-text__label-text {
    background-color: $form_element_color_2;
  }
  .app__profile__table-heading {
    color: $form_element_color_2;
  }
  .app__profile__big-icon {
    background-image: url(/img/user-icon_2.svg);
  }
  .app__profile__logout {
    color: $form_element_color_2;
  }
}

.skin3 {
  .app__profile__input-text__label-text {
    background-color: $secondary_form_color_3;
  }
  .app__profile__tab {
    background-color: $secondary_form_color_3;
  }
  .app__password__form {
    background-color: $secondary_form_color_3;
  }
  .app__profile__input-text__label-text {
    background-color: $form_element_color_3;
  }
  .app__profile__table-heading {
    color: $form_element_color_3;
  }
  .app__profile__big-icon {
    background-image: url(/img/user-icon_2.svg);
  }
  .app__profile__logout {
    color: $form_element_color_3;
  }
}

.skin4 {
  .app__profile__input-text__label-text {
    background-color: $secondary_form_color_4;
  }
  .app__profile__tab {
    background-color: $secondary_form_color_4;
  }
  .app__password__form {
    background-color: $secondary_form_color_4;
  }
  .app__profile__input-text__label-text {
    background-color: $form_element_color_4;
  }
  .app__profile__table-heading {
    color: $form_element_color_4;
  }
  .app__profile__big-icon {
    background-image: url(/img/user-icon_2.svg);
  }
  .app__profile__logout {
    color: $form_element_color_4;
  }
}

.skin5 {
  .app__profile__input-text__label-text {
    background-color: $secondary_form_color_5;
  }
  .app__profile__tab {
    background-color: $secondary_form_color_5;
  }
  .app__password__form {
    background-color: $secondary_form_color_5;
  }
  .app__profile__input-text__label-text {
    background-color: $form_element_color_5;
  }
  .app__profile__table-heading {
    color: $form_element_color_5;
  }
  .app__profile__big-icon {
    background-image: url(/img/user-icon_2.svg);
  }
  .app__profile__logout {
    color: $form_element_color_5;
  }
}

