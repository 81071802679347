.app__flex-table {
  margin: 0 25px;
}

.app__flex-table__row {
  display: flex;
  flex-direction: row;
  height: 62px;
  flex-flow: row wrap;
  border-top: 1px $table_border dashed;
  border-left: 1px $table_border dashed;
  border-right: 1px $table_border dashed;
  &:last-of-type {
    border-bottom: 1px $table_border dashed;
  }
}

.app__flex-table__cell {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px $table_border dashed;
  height: 62px;
  padding: 0 26px;
  font-family: $main_font;
  font-size: 12px;
  color: $table_border;
  cursor: pointer;
  &:last-of-type {
    border-right: none;
  }
  &:nth-child(even) {
    background-color: $exlight_grey;
  }
  position: relative;
}

.app__flex-table .app__flex-table__cell--selected {
  background-color: $form_element_color;
  color: $text_white;
}
.app__flex-table__text {
  display: block;
}

.app__flex-table__hiddenpanel {
  height: 127px;
  background-color: $exlight_grey;
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(200% + 3px);
  z-index: 10;
  box-sizing: border-box;
  border: 1px solid $table_border;
  padding: 10px 32px;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

.app__flex-table__cell--selected .app__flex-table__hiddenpanel {
  background-color: $form_element_color;
  color: $text_white;
}


.skin2 .app__flex-table__cell--selected .app__flex-table__hiddenpanel {
  background-color: $form_element_color_2;
  color: $text_white;
}

.skin3 .app__flex-table__cell--selected .app__flex-table__hiddenpanel {
  background-color: $form_element_color_3;
  color: $text_white;
}
.skin4 .app__flex-table__cell--selected .app__flex-table__hiddenpanel {
  background-color: $form_element_color_4;
  color: $text_white;
}
.skin5 .app__flex-table__cell--selected .app__flex-table__hiddenpanel {
  background-color: $form_element_color_5;
  color: $text_white;
}



.app__flex-table__row:last-of-type {
  .app__flex-table__hiddenpanel {
    bottom: -1px;
    top: auto;
  }
}

.app__flex-table__cell:last-of-type {
  .app__flex-table__hiddenpanel {
    left: auto;
    right: -1px;
  }
}

.app__showpanel {
  visibility: visible;
  opacity: 1;
}

.app__flex-table__close-panel {
  height: 14px;
  width: 14px;
  text-align: center;
  position: absolute;
  top: 3px;
  right: 12px;
  background-color: transparent;
  border: none;
  display: block;
  font-size: 16px;
  color: $table_border;
  cursor: pointer;
}

.app__flex-table__close-panel img {
  height: 14px;
  width: 14px;
}

.app__flex-table__expandbutton img {
  height: 10px;
  width: 10px;
}

.app__flex-table__expandbutton {
  height: 19px;
  width: 19px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  border: 1px solid black;
  padding: 0;
  text-align: center;
}

.app__flex-table__expandbutton.app__flex-table__expandbutton--hidden {
  display: none;
}

.app__flex-table__row--header {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

.app__flex-table__cell--header {
  flex: 1;
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
  font-family: $admin_font;
  font-size: 12px;
  font-weight: 700;
  color: $text_black;
}

.skin2 {
  .app__flex-table .app__flex-table__cell--selected {
    background-color: $form_element_color_2;
  }
}

.skin3 {
  .app__flex-table .app__flex-table__cell--selected {
    background-color: $highlight_3;
  }
}

.skin4 {
  .app__flex-table .app__flex-table__cell--selected {
    background-color: $form_element_color_4;
  }
}

.skin5 {
  .app__flex-table .app__flex-table__cell--selected {
    background-color: $secondary_form_color_5;
  }
}

