@import "variables";
@import "Footer";
@import "Header";
@import "Steps";
@import "Tabs";
@import "PageNavigation";
@import "Modal";
@import "Recipients";
@import "Profile";
@import "EmailGrid";
@import "Attachments";
@import "AdminTemplate";
@import "CustomSelect";
@import "SaraStyles";
@import "ContactModal";

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}


.landscapeOverlay {
  display: none;
}

@media screen and (max-device-width: 1020px) {
  .landscapeOverlay {
    background-color: #203045;
    z-index: 100000;
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -10px;
    text-align: center;
    padding-top: 5%;
    color: white;
    font-size: 12px;
    right: 0px;
    bottom: 0px;
  }
}

///

.app__container {
  box-sizing: border-box;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  /*border-left: 1px solid $border_color;
	border-right: 1px solid $border_color;*/
  padding-bottom: 80px;
  min-height: calc(100vh - 245px);
  /*min-height: 100vh;*/
}

///

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix {
  zoom: 1;
} /* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
} /* IE7 */

.checkbox-round {
  width: 14px;
  height: 14px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: 7px 7px 0 0;
}
.checkbox-round:checked {
  background-color: $form_element_color;
}

.skin2 .checkbox-round:checked {
  background-color: $form_element_color_2;
}
.skin4 .checkbox-round:checked {
  background-color: $form_element_color_4;
}
.skin5 .checkbox-round:checked {
  background-color: $form_element_color_5;
}

.checkbox-square {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 0;
  vertical-align: middle;
  border: 1px solid $table_border;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: 1px 7px 0 0;
}

.check-circle {
  background-image: url(/img/check-circle.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 52px;
  width: 52px;
}

.input__textarea {
  box-sizing: border-box;
  border: 1px solid $form_element_color;
  min-height: 78px;
  width: 210px;
  padding: 5px;
  margin: 0;
  &::placeholder {
  font-style:italic;
  }
}

.text__small{
  font-size: 9px;
  line-height: 11px;
  margin: 0 0 8px;
}

.app__center {
  margin: 0 auto;
}

// MISC

.app__email-preview {
  width: 480px;
  min-height: 443px;
  box-sizing: border-box;
  background-color: #333;
}

.app__select-topic {
  font-family: $main_font;
  margin-top: 50px;
  padding: 0 40px;
  box-sizing: border-box;
  text-align: left;
}

.app__dropdowns {
  position: absolute;
  sup {
    line-height: 0; 
    font-size: 75%; 
    vertical-align: 4px;
    mso-text-raise:20%;
  }
  p sup {
    line-height: 0; 
    font-size: 75%; 
    vertical-align: 4px;
    mso-text-raise:20%;
  }
}

.app__required-warning {
  font-size: 12px;
  max-width: 210px;
  color: $alert_red;
}

.select.app__select-topic__top-select {
  width: 100%;
  max-width: 812px;
}

.app__select-topic label {
  font-weight: bold;
  color: $form_element_color;
  font-size: 12px;
  padding: 0;
  margin: 8px 0 2px 0;
  display: block;
}

/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #fff;
  background-image: none;
  font-size: 12px;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

.app__select-topic__button {
  width: 210px;
  height: 30px;
  color: $text_white;
  background-color: $navigation_color;
  font-weight: 700;
  border: none;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  &:hover {
    background-color: $navigation_hover;
  }
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 210px;
  height: 30px;
  line-height: 3;
  /*background: #2c3e50;*/
  overflow: hidden;
  border: 1px solid $form_element_color;
  margin-bottom: 25px;
}
select {
  flex: 1;
  padding: 0 0.5em;
  color: #333;
  cursor: pointer;
  border-radius: 0;
}
/* Arrow */
.select::after {
  box-sizing: border-box;
  color: $text_white;
  /*font-family: "Font Awesome 5 Free"; 
  font-weight: 900;
  font-size: 20px;*/
  content: url(/img/down-arrow.svg);
  position: absolute;
  top: -9px;
  right: 0;
  padding: 0px 9px;
  height: 39px;
  background: $form_element_color;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
/* Transition */
.select:hover::after {
  color: #eee;
}

.unselectable {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Alternate Branding */

.skin2 {
  .select::after {
    background: $form_element_color_2;
  }
  .select {
    border: 1px solid $form_element_color_2;
  }
  .app__select-topic label {
    color: $form_element_color_2;
  }
  .check-circle {
    background-image: url(/img/check-circle_grey.svg);
  }

  .checkbox-round:checked {
    background-color: $form_element_color_2;
  }
  .input__textarea {
    border: 1px solid $form_element_color_2;
  }
}


.skin3 {
  .select::after {
    background: $form_element_color_3;
  }
  .select {
    border: 1px solid $form_element_color_3;
  }
  .app__select-topic label {
    color: $form_element_color_3;
  }
  .check-circle {
    background-image: url(/img/check-circle_grey.svg);
  }

  .checkbox-round:checked {
    background-color: $form_element_color_3;
  }
  .input__textarea {
    border: 1px solid $form_element_color_3;
  }
}

.skin4 {
  .select::after {
    background: $form_element_color_4;
  }
  .select {
    border: 1px solid $form_element_color_4;
  }
  .app__select-topic label {
    color: $form_element_color_4;
  }
  .check-circle {
    background-image: url(/img/check-circle_grey.svg);
  }

  .checkbox-round:checked {
    background-color: $form_element_color_4;
  }
  .input__textarea {
    border: 1px solid $form_element_color_4;
  }
}


.skin5 {
  .select::after {
    background: $form_element_color_5;
  }
  .select {
    border: 1px solid $form_element_color_5;
  }
  .app__select-topic label {
    color: $form_element_color_5;
  }
  .check-circle {
    background-image: url(/img/check-circle_grey.svg);
  }

  .checkbox-round:checked {
    background-color: $form_element_color_5;
  }
  .input__textarea {
    border: 1px solid $form_element_color_5;
  }
}

