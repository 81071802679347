.app__recipients__container {
  width: 930px;
  margin: 15px auto 0;
  font-family: $main-font;
  box-sizing: border-box;
}

.app__recipients__search {
  background-color: $form_element_color;
  width: 100%;
  min-height: 150px;
  border-radius: 20px 20px 0 0;
  padding: 20px 51px 20px 51px;
  box-sizing: border-box;
  text-align: left;
}

.app__recipients-input {
  border: none;
  background-color: $text_white;
  width: 100%;
  height: 26px;
  font-size: 10px;
  box-sizing: border-box;
  padding-left: 29px;
  margin-bottom: 7px;
}

.app__recipients-selected {
  border: none;
  background-color: $text_white;
  width: 100%;
  min-height: 59px;
  font-size: 10px;
  box-sizing: border-box;
  padding: 7px 29px 10px 29px;
}

.app__recipients-selected__list span {
  margin-right: 5px;
  line-height: 14px;
}

.app__recipients-selected span,
.app__recipients__list-table span {
  color: $navigation_color;
  text-transform: none;
  cursor: pointer;
}

.app__recipients-selected__title {
  font-size: 11px;
  color: $form_element_color;
  font-weight: 700;
  margin-bottom: 3px;
}

.app__recipients-dist-save {
  float: left;
  background: transparent;
  color: #fff;
  border: none;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 700;
  cursor: pointer;
  
  &:disabled {
    color: #ccc;
    cursor: default;
  }
}

.app__recipients-dist-actions {
  float: right;
  margin: 10px 0px;
  clear: right;
  width: 145px;
  text-align: center;
  display: inline-block;
  color: #FFF;
  font-size: 12px;

  button {
    cursor: pointer;
    background: transparent;
    color: #fff;
    border: none;
    font-size: 12px;
    font-weight: normal;
  }

  button.active {
    font-weight: bold;
  }
}

.app__recipients-dist-send-actions {
  float: right;
  margin: 10px 0px;
  clear: right;
  width: 255px;
  text-align: center;
  color: #FFF;
  font-size: 12px;

  button {
    cursor: pointer;
    background: transparent;
    color: #fff;
    border: none;
    font-size: 12px;
    font-weight: normal;
  }

  button.active {
    font-weight: bold;
  }
}

.app__recipients__heading {
  color: $text_white;
  font-weight: 700;
  font-size: 12px;
  margin: 0 0 8px;
}

.app__recipients__list {
  box-shadow: 0px 3px 6px #00000029;
  padding: 14px 40px;
  box-sizing: border-box;
}

.app__recipients__commands {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid gray;
  overflow: hidden;

  button {
    background: none;
    border: none;
    color: gray;
    align-items: center;
    font-weight: bold;
    padding: 5px 10px;
    outline: none;
    box-shadow: none;
  }

  button.active {
    color: $empty_color;
  }

  .section-1,
  .section-2,
  .section-3 {
    float: left;
    margin-right: 20px;
  }

  .section-1 {
    width: 25%;
  }

  .section-2 {
    width: 45%;
  }

  .section-2 div button:nth-child(1) {
    float: left;
    padding-left: 58px;
  }

  .section-3 {
    width: 20%;
  }

  .section-3-profile {
    width: 20%;
    float: right;
  }

  .section-2 div span {
    float: right;
    padding-right: 31px;
  }

  .section-1 span.subtitle,
  .section-2 span.subtitle {
    background: $form_element_color;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    display: block;
    padding: 5px;
    font-size: 11px;
  }

  span.footnote {
    display: block;
    color: gray;
    font-size: 11px;
  }

  p {
    color: gray;
    font-size: 11px;
    padding: 0px;
    margin: 0px;
    font-style: italic;
    text-align: left;
  }
}

.skin2 .app__recipients__commands .section-1 span.subtitle,
.skin2 .app__recipients__commands .section-2 span.subtitle {
  background: $form_element_color_2;
}


.skin3 .app__recipients__commands .section-1 span.subtitle,
.skin3 .app__recipients__commands .section-2 span.subtitle {
  background: $form_element_color_3;
}

.skin4 .app__recipients__commands .section-1 span.subtitle,
.skin4 .app__recipients__commands .section-2 span.subtitle {
  background: $form_element_color_4;
}

.skin5 .app__recipients__commands .section-1 span.subtitle,
.skin5 .app__recipients__commands .section-2 span.subtitle {
  background: $form_element_color_5;
}

.app__profile__contacts .app__recipients__commands {
  border: none;
}

.app__recipients__list select {
  border: 1px solid #333 !important;
  padding: 1px 17px;
}

.app__recipients__list-table {
  box-sizing: border-box;
  border-collapse: collapse;
  width: 100%;

  .select_all {
    font-size: 12px;
    text-align: left;
    span {
      text-decoration: underline;
    }
  }

  .sort_by {
    text-align: right;
    font-size: 12px;
    span {
      color: $form_element_color;
      font-style: italic;
    }
  }

  .checkbox-square:checked {
    background-color: $form_element_color;
    border-color: $form_element_color;
  }

  .checkbox-round,
  .checkbox-square {
    &.has-limit {
      background-color: $alert_red;
      border: 1px solid $alert_red;
      background-image: url(/img/warning.png);
      background-repeat: no-repeat;
      background-position: center;
      cursor: default;
    }

    &.has-optout:disabled  {
      background-color: $med_grey2;
      border: 1px solid $med_grey2;
      cursor: default;
    }

    &.has-optout-npi:disabled  {
      background-color: $med_grey2;
      border: 1px solid $med_grey2;
      cursor: default;
    }

    &.is-empty:disabled  {
      background-color: $empty_color;
      border: 1px solid $empty_color;
      background-repeat: no-repeat;
      background-position: center;
      cursor: default;
    }

    // &:disabled {
    //   background-color: #ddd;
    // }
  }

}

.app__recipients__list-table td {
  font-size: 12px;
  padding: 0 7px;
  line-height: 14px;
  // &:nth-child(-n+3) {
  // 	font-size: 12px;
  // }
  &:first-child {
    width: 30px;
    background-color: $text_white;
  }
}

.app__recipients__list-table th {
  font-size: 14px;
  padding: 0 5px;
  font-weight: 700;
  text-align: center;
}

.app__recipients__list-table td.divider {
  background-color: $table_dividers;
  color: $text_white;
  font-size: 15px;
  font-weight: 700;
}

.app__recipients__list-table tr {
  background-color: $exlight_grey;
  border-bottom: 3px solid $text_white;
  height: 29px;
  &:nth-child(2) {
    background-color: $text_white;
  }
  &:first-child {
    background-color: $text_white;
  }

  &.has-limit {
    background-color: $alert_red;
    color: $text_white;
  }

  &.has-optout {
    background-color: $med_grey2;
    color: $text_white;
  }

  &.has-optout-npi {
    background-color: $med_grey2;
    color: $text_white;
  }

  &.is-empty {
    background-color: $empty_color;
    color: $text_white;
  }
}

.app__recipients__list-table.dist-table th,
.app__recipients__list-table.dist-table td {
  text-align: left;

  &:first-child {
    width: 250px;
  }

  svg {
    cursor: pointer;
    color: $navigation_color;
  }
}

.app__recipients__list-table.dist-table td {
  background-color: $exlight_grey;
}

.app__recipients__row.app__recipients__row--selected {
  background-color: $secondary_form_color;
}

.app__recipients__row.app__recipients__row--no-email {
  background-color: $table_select;
}

.app__recipients__row.app__recipients__row--warning {
  background-color: $admin_warning_2;
}

.skin2 {
  .app__recipients-selected__title {
    font-size: 11px;
    color: $form_element_color_2;
  }

  .sort_by {
    text-align: right;
    font-size: 12px;
    span {
      color: $form_element_color_2;
      font-style: italic;
    }
  }

  .app__recipients__list-table {
    .checkbox-square:checked {
      background-color: $form_element_color_2;
      border-color: $form_element_color_2;
    }
  }

  .app__recipients__row.app__recipients__row--selected {
    background-color: $form_element_color_2;
  }
  .app__recipients__list-table td.divider {
    background-color: $secondary_form_color_2;
  }
  .app__recipients__search {
    background-color: $form_element_color_2;
  }
}

.skin3 {
  .app__recipients-selected__title {
    font-size: 11px;
    color: $form_element_color_3;
  }

  .sort_by {
    text-align: right;
    font-size: 12px;
    span {
      color: $form_element_color_3;
      font-style: italic;
    }
  }

  .app__recipients__list-table {
    .checkbox-square:checked {
      background-color: $form_element_color_3;
      border-color: $form_element_color_3;
    }
  }

  .app__recipients__row.app__recipients__row--selected {
    background-color: $form_element_color_3;
  }
  .app__recipients__list-table td.divider {
    background-color: $secondary_form_color_3;
  }
  .app__recipients__search {
    background-color: $form_element_color_3;
  }
}

.skin4 {
  .app__recipients-selected__title {
    font-size: 11px;
    color: $form_element_color_4;
  }

  .sort_by {
    text-align: right;
    font-size: 12px;
    span {
      color: $form_element_color_4;
      font-style: italic;
    }
  }

  .app__recipients__list-table {
    .checkbox-square:checked {
      background-color: $form_element_color_4;
      border-color: $form_element_color_4;
    }
  }

  .app__recipients__row.app__recipients__row--selected {
    background-color: $form_element_color_4;
  }
  .app__recipients__list-table td.divider {
    background-color: $secondary_form_color_4;
  }
  .app__recipients__search {
    background-color: $form_element_color_4;
  }
}

.skin5 {
  .app__recipients-selected__title {
    font-size: 11px;
    color: $form_element_color_5;
  }

  .sort_by {
    text-align: right;
    font-size: 12px;
    span {
      color: $form_element_color_5;
      font-style: italic;
    }
  }

  .app__recipients__list-table {
    .checkbox-square:checked {
      background-color: $form_element_color_5;
      border-color: $form_element_color_5;
    }
  }

  .app__recipients__row.app__recipients__row--selected {
    background-color: $form_element_color_5;
  }
  .app__recipients__list-table td.divider {
    background-color: $secondary_form_color_5;
  }
  .app__recipients__search {
    background-color: $form_element_color_5;
  }
}