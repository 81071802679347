.app__attachments {
  /*background-color: $exlight_grey;*/
  width: 100%;
  height: 100%;
  padding: 4px 21px 68px;
  box-sizing: border-box;
  font-family: $main-font;
  position: relative;
  margin-bottom: 6px;
  text-align: center;
}

.app__attachments__prompt {
  font-family: $admin_font;
  font-size: 14px;
  color: $form_element_color;
  margin: 17px 0 18px;
  display: block;
}

.app__attachments__images {
  /*margin-bottom: 36px;*/
  display: flex;
  flex-wrap: wrap;
}

.app__attachments__images p,
.app__attachments__videos p {
  text-align: center;
  font-size: 10px;
  margin: 4px 0 0;
  color: $form_element_color;
}

.app__attachments__header {
  background-color: $form_element_color;
  height: 29px;
  width: 100%;
  text-align: right;
}

.app__attachments__image-container {
  flex: 0 0 calc(16.66% - 18px);
  width: 148px;
  float: left;
  margin-bottom: 18px;
  margin-right: 18px;
  &:last-of-type {
    margin-right: 0;
  }
}

.app__attachments__image {
  height: 153px;
  /*background-image: url(/img/cat1.jpg);*/
  background-size: contain;
  background-color: $exlight_grey;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

//these styles just for demo! Remove!
.app__attachments__image-container {
  &:nth-of-type(1) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(2) .app__attachments__image {
    background-image: url(/img/cat3.jpg);
  }
  &:nth-of-type(3) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(4) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(5) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(6) .app__attachments__image {
    background-image: url(/img/cat4.jpg);
  }
  &:nth-of-type(7) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
}

.app__attachments__video-container {
  &:nth-of-type(1) .app__attachments__video {
    background-image: url(/img/videothumb1.png);
  }
  &:nth-of-type(2) .app__attachments__video {
    background-image: url(/img/videothumb2.png);
  }
  &:nth-of-type(3) .app__attachments__video {
    background-image: url(/img/videothumb1.png);
  }
  &:nth-of-type(4) .app__attachments__video {
    background-image: url(/img/videothumb1.png);
  }
}

//

.app__attachments__videos {
  display: flex;
  flex-wrap: wrap;
}

.app__attachments__video-container {
  flex: 0 0 calc(33.33% - 18px);
  width: 314px;
  float: left;
  margin-right: 18px;
  &:last-of-type {
    margin-right: 0;
  }
}
.app__attachments__video {
  height: 176px;
  /*background-image: url(/img/cat1.jpg);*/
  background-size: cover;
  background-position: 50% 50%;
}
.skin2 {
  .app__attachments__images p,
  .app__attachments__videos p {
    color: $table_border;
  }
  .app__attachments__prompt {
    color: $form_element_color_2;
  }
  .app__attachments__header {
    background-color: $form_element_color_2;
  }
}
.skin3 {
  .app__attachments__images p,
  .app__attachments__videos p {
    color: $table_border;
  }
  .app__attachments__prompt {
    color: $form_element_color_3;
  }
  .app__attachments__header {
    background-color: $form_element_color_3;
  }
}
.skin4 {
  .app__attachments__images p,
  .app__attachments__videos p {
    color: $table_border;
  }
  .app__attachments__prompt {
    color: $form_element_color_4;
  }
  .app__attachments__header {
    background-color: $form_element_color_4;
  }
}
.skin5 {
  .app__attachments__images p,
  .app__attachments__videos p {
    color: $table_border;
  }
  .app__attachments__prompt {
    color: $form_element_color_5;
  }
  .app__attachments__header {
    background-color: $form_element_color_5;
  }
}