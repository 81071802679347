.app__pagenavigation {
  position: fixed;
  bottom: 20px;
  // z-index: 1000;
  width: 100%;
}

.app__pagenavigation__icon {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-image: url(/img/navicon.svg);
  cursor: pointer;
  float: left;
  margin: 0 0 0 55px;
  &:nth-child(2) {
    float: right;
    margin: 0 55px 0 0;
    transform: rotate(180deg);
  }
  &:nth-child(2).send {
    border-radius: 0;
    float: right;
    margin: 0 28px 0 0;
    transform: rotate(0);
    background-image: url(/img/send-button.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 84px;
  }
  &:hover {
    opacity: 0.8;
  }
}
