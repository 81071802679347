.app__modal {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 6000;
  background: rgba(0, 0, 0, 0.3);
  font-family: $main_font;
}

.app__modal__container {
  width: 540px;
  height: 355px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
  margin: 0 auto;
  margin-top: calc(50vh - 176px);
  position: relative;
}

.app__modal__great-job {
  text-align: center;
  padding-top: 64px;
}

.app__modal__heading {
  font-size: 37px;
  color: $form_element_color;
  font-weight: 700;
}

.app__modal__text {
  font-size: 29px;
  color: $form_element_color;
  margin: 0;
  padding: 0;
}

.app__modal .loading {
  width: 250px;
}

.app__modal__green-check {
  position: absolute;
  left: 360px;
  top: 56px;
}

.app__modal__icons {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 55px;
}

.app__modal__icon-container {
  width: 50%;
  text-align: center;
}

.app__modal__icon {
  width: 70px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto 9px;
  cursor: pointer;
}

.app__modal__icon-link {
  font-size: 20px;
  text-decoration: underline;
  color: $navigation_color;
  font-weight: 400;
  &:hover {
    color: $navigation_hover;
  }
  cursor: pointer;
}

.app__modal__icon--sendagain {
  background-image: url(/img/envelope-open.svg);
}

.app__modal__icon--review {
  background-image: url(/img/analytics-monitor.svg);
}

.skin2 {
  .app__modal__heading,
  .app__modal__text {
    color: $form_element_color_2;
  }
}

.skin3 {
  .app__modal__heading,
  .app__modal__text {
    color: $form_element_color_3;
  }
}

.skin4 {
  .app__modal__heading,
  .app__modal__text {
    color: $form_element_color_4;
  }
}

.skin5 {
  .app__modal__heading,
  .app__modal__text {
    color: $form_element_color_5;
  }
}
