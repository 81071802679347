.app__subheader {
  box-sizing: border-box;
  padding-top: 15px;
  font-family: $main_font;
  text-align: center;
  height: 80px;
  border-bottom: 1px solid $border_color;
  box-shadow: 0 4px 6px -2px #f0ebf4;
  letter-spacing: -0.1px;
  font-size: 12px;
  color: $yet_another_grey;
  width: 100%;
}

.app__subheader__list {
  list-style: none;
  padding-inline-start: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.app__subheader__crumb {
  display: inline-block;
  margin-right: 42px;
  &:last-of-type {
    margin-right: 0;
  }
}

.app__subheader__crumb--selected {
  color: $form_element_color;
  font-weight: 600;
}

.app__top-line {
  width: 100%;
  height: 1px;
  background-color: $table-border;
  margin: 0;
}
.app__icon {
  width: 81px;
  height: 81px;
  border-radius: 50%;
  border: 2px solid $icon_border;
  background-image: url(/img/user.png);
  background-size: cover;
  cursor: pointer;
}

.app__bignumber {
  color: $text_white;
  box-sizing: border-box;
  font-size: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: $secondary_form_color;
  display: inline-block;
  font-weight: bold;
  padding-top: 5px;
  margin-right: 5px;
  text-align: center;
}

.app__subheader__crumb--selected .app__bignumber {
  background-color: $form_element_color;
  color: $text_white;
}

.skin2 {
  .app__bignumber {
    background-color: $secondary_form_color_2;
  }
  .app__subheader__crumb--selected .app__bignumber {
    background-color: $form_element_color_2;
  }
  .app__subheader__crumb--selected {
    color: $form_element_color_2;
  }
}

.skin3 {
  .app__bignumber {
    background-color: $secondary_form_color_3;
  }
  .app__subheader__crumb--selected .app__bignumber {
    background-color: $form_element_color_3;
  }
  .app__subheader__crumb--selected {
    color: $form_element_color_3;
  }
}

.skin4 {
  .app__bignumber {
    background-color: $med_grey;
  }
  .app__subheader__crumb {
    color: $med_grey; 
  }
  .app__subheader__crumb--selected .app__bignumber {
    background-color: $form_element_color_4;
  }
  .app__subheader__crumb.app__subheader__crumb--selected {
    color: $form_element_color_4;
  }
}

.skin5 {
  .app__bignumber {
    background-color: $med_grey;
  }
  .app__subheader__crumb {
    color: $med_grey; 
  }
  .app__subheader__crumb--selected .app__bignumber {
    background-color: $secondary_form_color_5;
  }
  .app__subheader__crumb.app__subheader__crumb--selected {
    color: $secondary_form_color_5;
  }
}