.app__header {
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid $border_color;
  border-bottom: 1px solid $border_color;
  height: 120px;
  background-image: url(/img/hero_xtampza_bg.png);
  background-size: cover;
  background-position: 50% 50%;
}

.app__header__container {
  max-width: 1024px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  background-image: url(/img/hero_xtampza_overlay.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 0 -14px;
}

.app__header__profile {
  position: absolute;
  top: 10px;
  right: 46px;
  text-align: center;
}

.app__header__icon {
  margin-bottom: -2px;
}

.app__header__username {
  font-family: $main_font;
  font-weight: 600;
  font-size: 9px;
  letter-spacing: 0.14px;
  color: $profile_text;
  text-transform: uppercase;
}

.app__header__tabs {
  margin-right: 156px;
}

.app__header__tab {
  width: 154px;
  height: 44px;
  float: right;
  margin-left: 3px;
  border-radius: 0 0 12px 12px;
  cursor: pointer;
  -webkit-box-shadow: 0px 6px 15px -5px rgba(140,134,134,0.74); 
  box-shadow: 0px 6px 15px -5px rgba(140,134,134,0.74);
  background-color: #fff;
  background-size: cover;

  /**
   * Brand Tabs
   */
  &.brand-ppv {
    background-image: url(/img/ppv-tab-bg--grey.png);
    background-position: -12px;
  }
  &.brand-nucynta {
    background-image: url(/img/nucynta-tab-bg--grey.png);
  }
  &.brand-xtampza {
    background-image: url(/img/xtampza-tab-bg--grey.png);
  }
  &.brand-belbuca {
    background-image: url(/img/belbuca-tab-bg--grey.png); 
  }
  &.brand-symproic {
    background-image: url(/img/symproic-tab-bg--grey.png); 
  }
  &.brand-collegium {
    background-image: url(/img/collegium-tab-bg--grey.png);
  }
}

/**
 * Highlighted/Active Brand Tabs
 */
.skin1 .app__header__tab {
  &.brand-xtampza {
    background-image: url(/img/xtampza-tab-bg.png);
  }
}
.skin2 .app__header__tab {
  &.brand-nucynta {
    background-image: url(/img/nucynta-tab-bg.png);
  }
}
.skin3 .app__header__tab {
  &.brand-ppv {
    background-image: url(/img/ppv-tab-bg.png);
  }
  &.brand-collegium {
    background-image: url(/img/collegium-tab-bg.png);
  }
}
.skin4 .app__header__tab {
  &.brand-belbuca {
    background-image: url(/img/belbuca-tab-bg.png); 
  }
}
.skin5 .app__header__tab {
  &.brand-symproic {
    background-image: url(/img/symproic-tab-bg.png); 
  }
}

/**
 * Different Skins
 */
.skin2 {
  .app__header {
    background-image: url(/img/hero_nucynta_bg.png);
  }
  .app__header__container {
    background-image: none;
  }
  .app__header__icon {
    border-color: $text_white;
  }
  .app__header__username {
    color: $text_white;
  }
}

.skin3 {
  .app__header {
    background-image: none;
    background-color: $tab_border_3;
  }
  .app__header__container {
    background-image: none;
    // background-image: url(/img/hero_collegium.png);
    background-position: 0;
  }
  .app__header__icon {
    border-color: $form_element_color_3;
  }
  .app__header__username {
    color: $form_element_color_3;
  }
}

.skin4 {
  .app__header {
    background: $tab_border_4 0% 0% no-repeat padding-box;
  }
  .app__header__container {
    background-image: none;
  }
  .app__header__icon {
    border-color: $profile_border_color_4;
  }
  .app__header__username {
    color: $profile_text_color_4;
  }
}

.skin5 {
  .app__header {
    background: $tab_border_5 0% 0% no-repeat padding-box;
  }
  .app__header__container {
    background-image: none;
  }
  .app__header__icon {
    border-color: $profile_border_color_5;
  }
  .app__header__username {
    color: $profile_text_color_5;
  }
}